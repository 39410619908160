import React from 'react'
import { Wrapper, H, Button, P } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL } from 'config'
import WrapperWithEdge from 'components/WrapperWithEdge'
import TelephoneNumber from 'components/TelephoneNumber'

const GetStarted = () => (
  <WrapperWithEdge direction="up" edgeBackgroundColor={COLOR.WHITE}>
    <Wrapper container containerPaddingTop="L">
      <Wrapper centered maxWidth={550} margin={[0, 'auto']}>
        <H size="L" decorative>
          Ready to finally tick your will off your to-do list?
        </H>
      </Wrapper>

      <Wrapper margin={['L', 'auto', 0]} centered>
        <Button.White
          wide
          href={TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL}
          data-track-event="click"
          data-track-element="cta"
          data-track-slice="todo-list-footer"
        >
          Book my free callback
        </Button.White>
        <Wrapper margin={['M', 0]}>
          <P color={COLOR.BLACK} margin={[0, 0, 'XS', 0]}>
            Or call us now <TelephoneNumber />
          </P>
          <P size="S" color={COLOR.BLACK}>
            We’re open 9am to 6pm, Monday to Friday.
          </P>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  </WrapperWithEdge>
)

export default GetStarted
