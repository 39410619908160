import React from 'react'
import { H, P, Button, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import {
  TELEPHONE_WILLS_CALLBACK_HOURS_GUARANTEE,
  CALLBACK_BOOKING_FEATURE_ENABLED,
  TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL,
} from 'config'
import StrokedSteps, { StrokedStep } from 'components/StrokedSteps'
import TrustpilotSummary from 'components/TrustpilotWidget/TrustpilotSummary'

import { STEPS } from './constants'

const callbackButton = (
  <Button.Primary
    href={TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL}
    wide
    data-track-event="click"
    data-track-element="cta"
    data-track-slice="3-easy-steps"
  >
    Book my free callback
  </Button.Primary>
)

const Steps = () => (
  <>
    <H
      size="L"
      centeredFromM
      decorative
      container
      containerPaddingBottom={0}
      maxWidth={600}
    >
      Make a will over the phone in three easy steps
    </H>

    <StrokedSteps>
      {STEPS.map(({ title, description, illustration, showCta }) => (
        <StrokedStep key={title} imagePath={`illustrations/${illustration}`}>
          <H size="M" margin={0}>
            {title}
          </H>
          <P margin={['S', 0, 'L']} maxWidth={460}>
            {description}
          </P>

          {showCta && callbackButton}
        </StrokedStep>
      ))}
    </StrokedSteps>

    <Wrapper container containerPaddingTop={0}>
      <Wrapper
        background={COLOR.WHITE}
        bordered
        borderRadius="M"
        maxWidth={700}
        margin={[0, 'auto']}
        padding="M"
        paddingFromM="L"
        paddingFromL="XL"
        centeredFromM
      >
        <H size="S">Get started today</H>
        <P margin={['S', 0, 'L']}>
          {CALLBACK_BOOKING_FEATURE_ENABLED
            ? 'Book a call to chat about your situation and set up an appointment with one of our will specialists'
            : `Leave us your name and number and we’ll get in touch within
            ${TELEPHONE_WILLS_CALLBACK_HOURS_GUARANTEE} hours.`}
        </P>

        {callbackButton}

        <Wrapper margin={['M', 0, 0]}>
          <TrustpilotSummary />
        </Wrapper>
      </Wrapper>
    </Wrapper>
  </>
)

export default Steps
